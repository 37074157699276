<template>
  <section id="h-intro">
    <header-component />

    <b-container class="mt-4 mb-4 px-md-0">
      <h2 class="sr-only">
        Actus
      </h2>
      <b-row align-h="end">
        <b-col lg="8">
          <b-row>
            <b-col
              id="h-actu"
              class="mt-4"
              cols="12"
            >
              <!-- French actu Campaign -->
              <div
                v-if="/^fr/.test($t('lang')) && is.before('2024/7/10')"
                class="p-4 mb-4 d-flex flex-column justify-content-between"
              >
                <h3
                  class="h2"
                  v-html="$t('home.intro.actu.dd.title')"
                ></h3>
                <p class="text-right m-0">
                  <b-button
                    :href="$t('home.intro.actu.dd.link')"
                    variant="outline-warning"
                  >
                    <span v-html="$t('home.intro.actu.dd.btn')"></span>
                    <i class="fas fa-chevron-right"></i>
                  </b-button>
                </p>
              </div>
              <!-- French actu -->
              <div
                v-else-if="/^fr/.test($t('lang'))"
                class="p-4 mb-4 d-flex flex-column justify-content-between"
              >
                <h3
                  class="h2"
                  v-html="$t('home.intro.actu.fr.title')"
                ></h3>
                <p class="text-right m-0">
                  <b-button
                    :href="$t('home.intro.actu.fr.link')"
                    variant="outline-warning"
                  >
                    <span v-html="$t('home.intro.actu.fr.btn')"></span>
                    <i class="fas fa-chevron-right"></i>
                  </b-button>
                </p>
              </div>
              <!-- English actu -->
              <div
                v-else
                class="p-4 mb-4 d-flex flex-column justify-content-between"
              >
                <h3
                  class="h2"
                  v-html="$t('home.intro.actu.en.title')"
                ></h3>
                <p class="text-right m-0">
                  <b-button
                    :href="$t('home.intro.actu.en.link')"
                    tag="span"
                    variant="outline-warning"
                  >
                    <span v-html="$t('home.intro.actu.en.btn')"></span>
                    <i class="fas fa-chevron-right"></i>
                  </b-button>
                </p>
              </div>
            </b-col>
            <b-col
              id="h-tube"
              md="6"
            >
              <div>
                <h3
                  v-html="$t('home.intro.tube.label')"
                ></h3>
                <!-- French video -->
                <a
                  v-if="/^fr/.test($t('lang'))"
                  :href="$t('home.intro.tube.fr.link')"
                >
                  <div class="p-2 mx-n2">
                    <div v-html="$t('home.intro.tube.fr.title')"></div>
                    <div class="img-thumbnail position-relative mt-4">
                      <img
                        alt=""
                        class="img-fluid w-100"
                        :src="$t('home.intro.tube.fr.img')"
                      />
                      <img
                        alt=""
                        :src="`${$t('baseurl')}img/btn-play.png`"
                      />
                    </div>
                  </div>
                </a>
                <!-- English video -->
                <a
                  v-else
                  :href="$t('home.intro.tube.en.link')"
                >
                  <div class="p-2 mx-n2">
                    <div v-html="$t('home.intro.tube.en.title')"></div>
                    <div class="img-thumbnail position-relative mt-4">
                      <img
                        alt=""
                        class="img-fluid w-100"
                        :src="$t('home.intro.tube.en.img')"
                      />
                      <img
                        alt=""
                        :src="`${$t('baseurl')}img/btn-play.png`"
                      />
                    </div>
                  </div>
                </a>
              </div>
            </b-col>
            <b-col
              id="h-blog"
              md="6"
            >
              <div>
                <h3
                  v-html="$t('home.intro.blog.label')"
                ></h3>
                <div
                  v-for="(article, index) in articles[$t('lang').replace(/^(?!fr$).*$/, 'en')]"
                  :key="index"
                  class="p-2 mb-2 mx-n2"
                >
                  <p class="small mb-1">
                    <i class="fas fa-calendar"></i>
                    <time :datetime="article.date">
                      {{ article.date.split('T')[0] }}
                    </time>
                  </p>
                  <div>
                    <a :href="article.url">
                      <span
                        v-html="article.title"
                      ></span>
                      <p class="mt-2 text-right">
                        <span class="btn btn-outline-warning">
                          <span v-html="$t('home.intro.blog.read')"></span>
                          <i class="fas fa-chevron-right"></i>
                        </span>
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import HeaderComponent from '../../components/Header.vue';

export default {
  components: {
    HeaderComponent,
  },

  data() {
    return {
      articles: {
        fr: [],
        en: [],
      },
    };
  },

  created() {
    if (/^fr/.test(this.$t('lang'))) {
      this.getArticles('fr');
    } else {
      this.getArticles('en');
    }
  },

  mounted() {
    /* Preload articles (in case the user changes language) */
    if (/^fr/.test(this.$t('lang'))) {
      this.getArticles('en');
    } else {
      this.getArticles('fr');
    }
  },

  methods: {
    getArticles(lg) {
      const url = (lg === 'fr')
        ? 'https://framablog.org/tag/framaccueil/json'
        : 'https://framablog.org/tag/framahome/json';
      fetch(url)
        .then(res => res.json())
        .then((data) => {
          data.items.forEach((item) => {
            if (this.articles[lg].length < 2) {
              this.articles[lg].push({
                url: item.url,
                title: item.title,
                date: item.date_published,
              });
            }
          });
        })
        .catch((err) => { console.error(err) }); // eslint-disable-line
    },
  },
};
</script>

<style lang="scss">
#h-intro {
  position: relative;

  &::before {
    background:
      var(--f-f8)
      url('../../../public/img/bg-poster.jpg')
      no-repeat
      center;
    background-size: cover;
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

#h-actu {
  > div {
    background:
      var(--f-g10)
      url('../../../public/img/bg-cloud-news.jpg')
      no-repeat
      bottom left;
    background-size: cover;
    border: .75rem solid var(--f-g10);
    min-height: 340px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding-left: 0 !important;
  }

  .h2 {
    background: var(--f-g10);
    color: #fff;
    display: inline;
    font-family: 'Tovari Sans', sans-serif;
    font-size: 2.25rem;
    line-height: 2.5rem;
    margin-top: 1rem;
    @media (min-width: 576px) {
      max-width: 66%;
    }
    padding: 0.5rem .5rem .5rem 1rem;
  }
}

// [lang="fr"] #h-actu > div {
//   background-image: url('../../../public/img/bg-emancip-asso.jpg');
// }

#h-blog,
#h-tube {
  margin: 5rem auto;

  > div {
    background: var(--f-g2);
    border-radius: 1.75rem;
    padding: .5rem 1.5rem;
    position: relative;
    z-index: 1;
    height: 100%;

    div:hover, div:focus {
      background: var(--f-g1);
      border-radius: .75rem;
    }

    a {
      background-image: none;
      color: var(--f-g9);
      font-size: 1.25rem;
      font-weight: bold;

      &:hover, &:focus {
        text-decoration: none;
      }

      p {
        margin-bottom: .5rem;
      }

      p:last-of-type {
        line-height: 1.2;
      }
    }
  }

  h3 {
    background: var(--f-o6);
    border-radius: .5rem;
    color: #fff;
    font-family: 'Tovari Sans', sans-serif;
    font-size: 1.5rem;
    margin: -1.5rem auto 1.5rem 0;
    padding: .25rem .75rem 0rem;
    text-align: center;
    text-transform: uppercase;
    transform: rotate(-2deg);
    width: fit-content;
  }

  .small {
    color: var(--f-g7) !important;
  }
}

#h-tube img {
  + img {
    position: absolute;
    top: calc(50% - 25%);
    left: calc(50% - 12.5%);
    width: 25%;
  }
}
</style>
