<template>
  <div>
    <b-container
      id="f-page"
      tag="main"
    >
      <h1 class="col-12 text-center">
        <span v-html="$t('asso.title')"></span>
      </h1>
      <div id="lastyear">
        <h2 v-html="$t('sfs.why.numbers.title', { year: '2021' })"></h2>

        <h3
          v-html="$t('sfs.why.numbers.subtitle')"
        ></h3>
        <b-row>
          <b-col
            v-for="n in 3"
            :key="n"
            md="4"
            class="py-4 d-flex flex-column"
          >
            <div
              aria-hidden="true"
              class="text-center"
            >
              <i
                :class="`${
                  ['fas fa-house','fas fa-users','far fa-file-lines'][n - 1]
                } fa-shape-circle fa-border fa-lg mb-2`"
              ></i>
            </div>
            <div
              class="h-100"
              v-html="$t(`sfs.why.numbers.list${n}`, numbers)"
            ></div>
          </b-col>
        </b-row>
      </div>

      <hr />

      <div
        class="col-lg-8 p-0 mt-5"
        v-html="$t('asso.infos')"
      ></div>

      <p class="text-center">
        <img
          style="width:180px;"
          alt=""
          :src="`${$t('/')}img/biglogo-notxt.png`"
        />
      </p>

      <hr />

      <h2
        class="mt-5"
        v-html="$t('asso.data.title')"
      ></h2>

      <b-card bg-variant="light mx-auto col-md-10 mb-5">
        <dl class="row">
          <dt
            class="col-md-4 col-lg-3"
            v-html="$t('asso.data.dt.juridical')"
          ></dt>
          <dd v-html="$t('asso.data.dd.juridical')"></dd>
        </dl>
        <dl class="row">
          <dt
            class="col-md-4 col-lg-3"
            v-html="$t('asso.data.dt.coprez')"
          ></dt>
          <dd v-html="$t('legals.coprezName')"></dd>
        </dl>
        <dl class="row">
          <dt
            class="col-md-4 col-lg-3"
            v-html="$t('asso.data.dt.dg')"
          ></dt>
          <dd v-html="$t('legals.codirName')"></dd>
        </dl>
        <dl class="row">
          <dt
            class="col-md-4 col-lg-3"
            v-html="$t('asso.data.dt.registration')"
          ></dt>
          <dd>
            2004<br />
            (<a
              href="https://framadrive.org/s/8LMJYrEgYpsaycq"
              rel="nofollow"
              v-html="$t('asso.statuts')"
            ></a>)<br />
            (<a
              href="https://framadrive.org/s/aZHSoGCTQyaNScz"
              rel="nofollow"
              v-html="$t('asso.ri')"
            ></a>)
          </dd>
        </dl>
        <dl class="row">
          <dt
            class="col-md-4 col-lg-3"
            v-html="$t('asso.data.dt.staff')"
          ></dt>
          <dd v-html="$t('asso.data.dd.staff')"></dd>
        </dl>
        <dl class="row">
          <dt
            class="col-md-4 col-lg-3"
            v-html="$t('asso.data.dt.headquarters')"
          ></dt>
          <dd>Lyon, France</dd>
        </dl>
        <dl class="row">
          <dt
            class="col-md-4 col-lg-3"
            v-html="$t('asso.data.dt.org')"
          ></dt>
          <dd v-html="$t('asso.data.dd.org')"></dd>
        </dl>
        <dl class="row">
          <dt
            class="col-md-4 col-lg-3"
            v-html="$t('asso.data.dt.id')"
          ></dt>
          <dd>
            <a
              href="https://framadrive.org/s/wQF5rSPZHYkan4q"
              rel="nofollow"
              v-html="$t('asso.data.dd.id')"
            ></a>
          </dd>
        </dl>
        <dl class="row">
          <dt
            class="col-md-4 col-lg-3"
            v-html="$t('asso.data.dt.charte')"
          ></dt>
          <dd>
            <a
              href="../manifest"
              v-html="$t('asso.data.dd.manifest')"
            ></a> -
            <a
              href="../cgu"
              v-html="$t('asso.data.dd.cgu')"
            ></a> -
            <a
              href="../moderation"
              v-html="$t('asso.data.dd.moderation')"
            ></a>
          </dd>
        </dl>
        <dl class="row">
          <dt
            class="col-md-4 col-lg-3"
            v-html="$t('asso.data.dt.reports')"
          ></dt>
          <dd>
            <ul>
              <li>
                2022&nbsp;:
                <a
                  href="https://framadrive.org/s/ojjizHPEnACRzRt"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsFinancial')"
                ></a>&nbsp;-&nbsp;
                <a
                  href="https://framadrive.org/s/rX8L6bBDCy97DSw"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsActivities')"
                ></a>
              </li>
              <li>
                2021&nbsp;:
                <a
                  href="https://framadrive.org/s/ataFDq5zpbEkbRS"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsFinancial')"
                ></a>&nbsp;-&nbsp;
                <a
                  href="https://framadrive.org/s/feZnKHgEcecQDAk"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsActivities')"
                ></a>
              </li>
              <li>
                2020&nbsp;:
                <a
                  href="https://framadrive.org/s/CdkM2pAxjbtZHiX"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsFinancial')"
                ></a>&nbsp;-&nbsp;
                <a
                  href="https://framadrive.org/s/g3qHx7ee7zCn9RQ"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsActivities')"
                ></a>
              </li>
              <li>
                2019&nbsp;:
                <a
                  href="https://framadrive.org/s/QWoBdGCG7A68orH"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsFinancial')"
                ></a>&nbsp;-&nbsp;
                <a
                  href="https://framadrive.org/s/xr8QnfwZkwpscke"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsActivities')"
                ></a>
              </li>
              <li>
                2018&nbsp;:
                <a
                  href="https://framadrive.org/s/7nn4eE9bfFttM5C"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsFinancial')"
                ></a> -
                <a
                  href="https://framadrive.org/s/7mj6nDEbwpitEQ9"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsActivities')"
                ></a>
              </li>
              <li>
                2017&nbsp;:
                <a
                  href="https://framadrive.org/s/24mf84a2PXboaKM"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsFinancial')"
                ></a>
              </li>
              <li>
                2016&nbsp;:
                <a
                  href="https://framadrive.org/s/EaF9fzqzyq2m3nn"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsFinancial')"
                ></a>
              </li>
              <li>
                2015&nbsp;:
                <a
                  href="https://framadrive.org/s/ZdqGYTbsB6Fssb3"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsFinancial')"
                ></a>
              </li>
              <li>
                2014&nbsp;:
                <a
                  href="https://framadrive.org/s/KRtoXwTrjGy3goF"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsResults')"
                ></a>
              </li>
              <li>
                2013&nbsp;:
                <a
                  href="https://framadrive.org/s/GQocAT7pfkAHgpR"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsMAndF')"
                ></a>
              </li>
              <li>
                2012&nbsp;:
                <a
                  href="https://framadrive.org/s/Gck36dStHibADzR"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsMAndF')"
                ></a>
              </li>
              <li>
                2011&nbsp;:
                <a
                  href="https://framadrive.org/s/MTn6R4dT7XDMxtw"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsMAndF')"
                ></a>
              </li>
              <li>
                2010&nbsp;:
                <a
                  href="https://framadrive.org/s/iCbAGpCBwCsRgt2"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsMAndF')"
                ></a>
              </li>
              <li>
                2009&nbsp;:
                <a
                  href="https://framadrive.org/s/jLPGA7fdSqjg4FL"
                  rel="nofollow"
                  v-html="$t('asso.data.dd.reportsMAndF')"
                ></a>
              </li>
            </ul>
          </dd>
        </dl>
        <dl class="row">
          <dt
            class="col-md-4 col-lg-3"
            v-html="$t('asso.data.dt.newsletter')"
          ></dt>
          <dd>
            <a
              :href="$t('link.newsletter')"
              v-html="$t('asso.data.dd.newsletter')"
            ></a>
          </dd>
        </dl>
        <dl class="row">
          <dt
            class="col-md-4 col-lg-3"
            v-html="$t('asso.data.dt.press')"
          ></dt>
          <dd>
            <a
              :href="$t('link.press')"
              v-html="$t('asso.data.dd.press')"
            ></a>
          </dd>
        </dl>
        <dl class="row">
          <dt
            class="col-md-4 col-lg-3"
            v-html="$t('asso.data.dt.graphics')"
          ></dt>
          <dd>
            <a
              href="../graphics"
              v-html="$t('asso.data.dd.graphics')"
            ></a> -
            <a
              href="https://participer.framasoft.org/fr/print/"
              v-html="$t('asso.data.dd.print')"
            ></a>
          </dd>
        </dl>
        <dl class="row">
          <dt
            class="col-md-4 col-lg-3"
            v-html="$t('asso.data.dt.community')"
          ></dt>
          <dd>
            <a
              href="https://soutenir.framasoft.org/benevolat"
              v-html="$t('asso.data.dd.benevalo')"
            ></a>
          </dd>
        </dl>
      </b-card>
    </b-container>

    <f-news v-if="/^fr/.test(this.$t('lang'))" />
  </div>
</template>

<script>
import FNews from '../components/FNews.vue';

export default {
  components: {
    FNews,
  },

  data() {
    return {
      numbers: {
        years: `<b>${this.$t('year.current') - 2001}</b>`,
        members: `<b>${this.$t('metrics.members')}</b>`,
        employees: `<b>${this.$t('metrics.employees')}</b>`,
        projects: `<b>${this.$t('metrics.projects')}</b>`,
        services: `<b>${this.$t('metrics.services')}</b>`,
        ucontrib: `<b>${this.$t('metrics.ucontrib')}</b>`,
        usupport: `<b>${this.$n(+this.$t('metrics.usupport'))}</b>`,
        uvisits: `<b>${this.$n(+this.$t('metrics.uvisits'))}</b>`,
        dlibre: `<b>${this.$n(+this.$t('metrics.dlibre'))}</b>`,
        dblog: `<b>${this.$n(+this.$t('metrics.dblog'))}</b>`,
        events: `<b>${this.$t('metrics.events')}</b>`,
      },
    };
  },
};
</script>

<style lang="scss">
#f-page #lastyear {
  h2 {
    background: none;
    color: var(--f-g10);
    font-size: 2.4rem;
    @media (min-width: 576px) {
      font-size: 3rem;
    }
    margin: 0 0 1rem;
    padding: 0;
    text-transform: none;
    transform: none;
  }

  ul {
    list-style: none;
    padding: 1rem 1.5rem;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  li {
    margin-bottom: .5rem;

    b {
      font-size: 1.2rem;
      font-weight: normal;
    }
  }

  .col-md-4 {
    > div:last-of-type {
      background: var(--f-o3);
      border-radius: 2rem 0 2rem 2rem;
    }

    .fa-shape-circle {
      border-color: var(--f-o4);
      color: var(--f-o6);
      margin-bottom: .5rem;
    }

    &:first-of-type {
      > div:last-of-type {
        background: var(--f-r3);
        border-radius: 0 2rem 2rem 2rem;
      }

      .fa-shape-circle {
        border-color: var(--f-r4);
        color: var(--f-r6);
      }
    }

    &:last-of-type {
      > div:last-of-type {
        background: var(--f-b3);
        border-radius:  2rem 2rem 0 2rem;
      }

      .fa-shape-circle {
        border-color: var(--f-b4);
        color: var(--f-b6);
      }
    }
  }
}
</style>
